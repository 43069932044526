import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivatedRoute} from "@angular/router";
import {map, Observable} from "rxjs";
import {Event, EventSponsor} from "../types/event";
import {Meta, Title} from "@angular/platform-browser";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-event',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.less']
})
export class EventComponent implements OnInit {
  public event$!: Observable<Event>;

  public constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
  ) {
  }

  ngOnInit(): void {
    this.event$ = this.route.data.pipe(
      map(
        data => data['event']
      )
    );

    this.event$.subscribe(event => {
      this.meta.removeTag('name="description"');
      this.titleService.setTitle(event.name);
      if (event.metaDescription) {
        this.meta.addTag({name: 'description', content: event.metaDescription});
      }
    })
  }

  public getEventPrices(event: Event): string {
    if (!event.priceMin && !event.priceMax) {
      return '';
    }

    let prices: string = '';
    if (event.priceMin) {
      prices += event.priceMin.toFixed(2);

      if (event.priceMax) {
        prices += `&ndash;`
      }
    }

    if (event.priceMax) {
      prices += event.priceMax.toFixed(2);
    }

    prices += ' &euro;';

    return prices;
  }

  public getHeaderStyle(event: Event): object {
    let styles: any = {};

    if (event.imageUrl) {
      styles.backgroundImage = `url(${event.imageUrl})`;
    }

    return styles;
  }

  public getCopyrights(event: Event): string {
    return event.copyrights || event.name;
  }

  public sponsorLogoUrl(sponsor: EventSponsor): string {
    return `${environment.cdn.url}/cdn/${sponsor.logoUrl}`;
  }

  public sponsorsTitle(event: Event): string {
    return event.sponsorsTitle ?? 'Sponsors of Event';
  }
}
