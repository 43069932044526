import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GoogleMapsModule} from "@angular/google-maps";
import {MapWidget} from "./types/map-widget";

@Component({
  selector: 'app-widget-map',
  standalone: true,
  imports: [CommonModule, GoogleMapsModule],
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.less']
})
export class MapComponent implements OnInit {
  @Input() widget!: MapWidget;
  public options: google.maps.MapOptions = {};
  public markerPosition: google.maps.LatLngLiteral = {
    lat: 0,
    lng: 0,
  };

  ngOnInit(): void {
    this.options = {
      center: {lat: this.widget.data.latitude, lng: this.widget.data.longitude},
      zoom: this.widget.data.zoom
    };

    this.markerPosition = {
      lat: this.widget.data.latitude,
      lng: this.widget.data.longitude,
    }
  }
}
