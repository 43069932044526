<form>
  <div *ngFor="let field of widget.data.components" class="form-control">
    <label>
      <span>{{ field.label }}</span>
      <input [type]="field.type" [placeholder]="field.label">
    </label>
  </div>
  <div class="form-control">
    <button class="button button-default">{{ widget.data.buttonText }}</button>
  </div>
</form>
