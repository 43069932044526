import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  private videoStarted: Subject<boolean> = new Subject<boolean>();

  public sendVideoStartedEvent(): void {
    this.videoStarted.next(true);
  }

  public getVideoStartedEvent(): Observable<boolean> {
    return this.videoStarted.asObservable();
  }
}
