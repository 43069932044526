import {Component, Input, OnInit} from '@angular/core';
import {CommonModule, formatDate} from '@angular/common';
import {EventsService} from "../../../events/events.service";
import {EventsFilterResponse} from "../../../events/types/events-filter-response";
import {RouterLink} from "@angular/router";
import {Event, EventArtist} from "../../../events/types/event";
import {UpcomingEvents} from "./types/upcoming-events";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-widget-upcoming-events',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './upcoming-events.component.html',
  styleUrls: [
    './upcoming-events.component.less',
    '../../../../styles/events/_events-list.less'
  ]
})
export class UpcomingEventsComponent implements OnInit {
  @Input() widget!: UpcomingEvents;
  public events!: Event[];

  public constructor(
    private eventsService: EventsService
  ) {
  }

  ngOnInit(): void {
    let date = formatDate(new Date(), 'yyyy-MM-dd 00:00:00', 'en');
    this.eventsService.getEvents(
      this.widget.data.filters?.categories,
      date,
      undefined,
      this.widget.data.limit,
    )
      .subscribe(
        (response: EventsFilterResponse) => {
          this.events = response.events;
        }
      );
  }

  public getClasses(): string[] {
    return [
      `${this.widget.data.view}-view`
    ];
  }

  public getEventArtists(event: Event): string {
    return event.artists.filter(m => m.name).map(a => this.getArtistText(a)).join(', ');
  }

  private getArtistText(artist: EventArtist) {
    if (!artist.isGroup) {
      return artist.name.trim();
    }

    return `<strong>${artist.name}</strong> | ${artist.members.filter(m => m.name).map(m => m.name.trim()).join(', ')}`;
  }

  public getEventStyle(event: Event): string[] {
    return [
      `event-style--${event.style}`
    ];
  }

  public getEventImageUrl(event: Event): string | null {
    if (!event.image) {
      return null;
    }

    return `url(${environment.cdn.url}/events/thumbnails/medium/${event.image})`;
  }

  public getEventCopyrights(event: Event): string {
    return event.copyrights || event.name;
  }

  public getEventDisplayName(event: Event): string {
    return event.name;
  }
}
