import {Component, forwardRef, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {Tab} from "./types/tab";
import {AccordionModule} from "primeng/accordion";
import {LayoutComponent} from "../../layout/layout.component";
import {WidgetComponent} from "../../../widget/widget/widget.component";

@Component({
  selector: 'app-section-tab',
  standalone: true,
  imports: [CommonModule, AccordionModule, forwardRef(() => LayoutComponent), WidgetComponent],
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.less']
})
export class TabComponent {
  @Input() section!: Tab;

}
