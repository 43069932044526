import {Component, OnInit} from '@angular/core';
import {Menu} from "../types/menu";
import {MenuService} from "../menu.service";
import {SharedModule} from "../../shared/shared.module";
import {MenuItemComponent} from "../menu-item/menu-item.component";
import {Event, NavigationStart, Router} from "@angular/router";

@Component({
  selector: 'app-popup-menu',
  standalone: true,
  imports: [SharedModule, MenuItemComponent],
  templateUrl: './popup-menu.component.html',
  styleUrls: ['./popup-menu.component.less']
})
export class PopupMenuComponent implements OnInit {
  readonly POSITION: string = 'popup';
  readonly POSITION_MOBILE: string = 'top';

  public menu: Menu;
  public topMenu: Menu;
  public menuIsActive: boolean = false;

  constructor(
    private menuService: MenuService,
    private router: Router
  ) {
    this.menu = this.menuService.createEmptyMenu();
    this.topMenu = this.menuService.createEmptyMenu();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.menuIsActive = false;
      }
    });
  }

  ngOnInit(): void {
    this.menuService.menus$
      .subscribe((menus: Menu[]) => {
        let menu = menus.find(m => m.position === this.POSITION);
        if (menu !== undefined) {
          this.menu = menu;
        }
        let topMenu = menus.find(m => m.position === this.POSITION_MOBILE);
        if (topMenu !== undefined) {
          this.topMenu = topMenu;
        }
      });
  }

  public onPopupMenuClick(): void {
    this.menuIsActive = !this.menuIsActive;
  }

}
