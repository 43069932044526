import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {SiteOptions} from "./types/site-options";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SiteOptionsService {
  private siteOptions: ReplaySubject<SiteOptions> = new ReplaySubject<SiteOptions>(1);

  constructor(private http: HttpClient) {
  }

  get siteOptions$(): Observable<SiteOptions> {
    return this.siteOptions.asObservable();
  }

  public get(): Observable<SiteOptions> {
    return this.http.get<SiteOptions>(`${environment.api.url}/ui/v1/site-options`)
      .pipe(
        tap((siteOptions: SiteOptions) => {
          this.siteOptions.next(siteOptions)
        })
      );
  }

  public createEmptySiteOptions(): SiteOptions {
    return {
      copyrights: '',
      googleMapsApiKey: '',
    }
  }
}
