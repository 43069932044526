import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {BlogPost} from "./types/blog-post";
import {BlogCategory} from "./types/blog-category";

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  constructor(private http: HttpClient) {
  }

  public getBlogPosts(
    categoryIds?: number[],
    limit?: number,
    dateFrom?: string,
    dateTo?: string,
  ): Observable<BlogPost[]> {
    let params: HttpParams = new HttpParams();
    if (categoryIds) {
      categoryIds.forEach(id => params = params.append('categoryIds[]', id))
    }
    if (limit) {
      params = params.append('limit', limit);
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom);
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo);
    }

    return this.http.get<BlogPost[]>(`${environment.api.url}/ui/v1/blog-posts`, {
      params: params
    });
  }

  public getBlogBySlug(slug: string): Observable<BlogPost> {
    return this.http.get<BlogPost>(`${environment.api.url}/ui/v1/blog/by-slug/${slug}`);
  }

  public createEmptyBlogPost(): BlogPost {
    return {
      dateCreated: '',
      image: '',
      imageUrl: '',
      metaDescription: '',
      title: '',
      slug: '',
      text: '',
      categories: [],
    }
  }

  public createEmptyCategory(): BlogCategory {
    return {
      name: '',
    }
  }
}
