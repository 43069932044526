import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DownloadLink} from "./types/download-link";
import {environment} from "../../../../environments/environment";
import {FilesService} from "../../../files/file-manager.service";
import {File} from "../../../files/file";

@Component({
  selector: 'app-widget-download-link',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './download-link.component.html',
  styleUrls: ['./download-link.component.less']
})
export class DownloadLinkComponent implements OnInit {
  @Input() widget!: DownloadLink;
  public file!: File;

  public constructor(
    private fileManagerService: FilesService,
  ) {
  }

  ngOnInit(): void {
    this.fileManagerService.getFile(this.widget.data.file).subscribe(
      file => this.file = file
    )
  }

  public getFileUrl(): string {
    if (!this.file) {
      return '';
    }

    return `${environment.api.url}/ui/v1/file/download/${this.file.id}`;
  }
}
