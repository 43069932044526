import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LastUpdate} from "./types/last-update";
import {PageService} from "../../../page/page.service";

@Component({
  selector: 'app-widget-last-update',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './last-update.component.html',
  styleUrls: ['./last-update.component.less']
})
export class LastUpdateComponent implements OnInit {

  @Input() widget!: LastUpdate;
  public lastUpdated: Date | null = null;

  public constructor(
    private pageService: PageService,
  ) {
  }

  ngOnInit(): void {
    this.pageService.currentPage$.subscribe(
      page => {
        this.lastUpdated = new Date(page.updatedAt);
      }
    );
  }

  public getClasses(): string[] {
    return [
      `text-${this.widget.data.textAlign || 'left'}`
    ];
  }
}
