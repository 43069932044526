import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterMenuComponent} from "../../menu/footer-menu/footer-menu.component";
import {SocialComponent} from "../../social/social.component";
import {SiteOptionsService} from "../../site-options/site-options.service";
import {SiteOptions} from "../../site-options/types/site-options";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, FooterMenuComponent, SocialComponent],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {
  public copyrights: string = '';

  constructor(private siteOptionsService: SiteOptionsService) {
  }

  ngOnInit(): void {
    this.siteOptionsService.siteOptions$
      .subscribe((siteOptions: SiteOptions) => {
        this.copyrights = siteOptions.copyrights;
      });
  }
}
