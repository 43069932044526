export const environment = {
    production: true,
    api: {
        url: 'https://api.telemann.de/api'
    },
    gallery: {
        apiKey: '6A351E2C-C50B-4DCA-8102-C451576002CA'
    },
    cdn: {
        url: 'https://api.telemann.de/uploads'
    }
};
