import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SocialIcon} from "../social/types/social-icon";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SocialService {
  constructor(private http: HttpClient) {
  }

  public getSocialIcons(): Observable<SocialIcon[]> {
    return this.http.get<SocialIcon[]>(`${environment.api.url}/ui/v1/social-icons`);
  }
}
