import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterLink} from "@angular/router";
import {MenuItem} from "../types/menu-item";

@Component({
  selector: 'app-menu-item',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.less'],
})
export class MenuItemComponent {
  @Input() customClass!: string;
  @Input() item!: MenuItem;

  protected expanded: boolean = false;

  public getUrl(): string {
    switch (this.item.type) {
      case 'url':
        return this.item?.data?.url?.split('#')[0];
      case 'page':
      default:
        return 'homepage';
    }
  }

  public getFragment(): string | undefined {
    if (this.item?.data?.url?.includes('#')) {
      return this.item.data.url.split('#')[1];
    }

    return undefined;
  }

  protected onMenuItemClick() {
    if (this.item.children.length > 0) {
      this.expanded = !this.expanded;
    }
  }
}
