<div class="blog-widget">
  <article *ngFor="let article of blogPosts" class="short-article-widget">
    <div class="short-article--image" [ngStyle]="getStyles(article)"></div>
    <div class="short-article--content">
      <h4>{{article.title}}</h4>
      <div [innerHtml]="excerpt(article)"></div>
      <div class="article-button text-right">
        <a class="button button-default" [routerLink]="['/', 'blog', article.slug]">weiterlesen</a>
      </div>
    </div>
  </article>
</div>
