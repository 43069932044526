import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Title} from "./types/title";

@Component({
  selector: 'app-widget-title',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.less']
})
export class TitleComponent {
  @Input() widget!: Title;

  public getStyle(): object {
    return {
      margin: this.widget.data.margin ?? null,
    }
  }
}
