import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PagedContent} from "./types/paged-content";
import {MatTabsModule} from "@angular/material/tabs";

@Component({
  selector: 'app-layout-paged-content',
  standalone: true,
  imports: [CommonModule, MatTabsModule],
  templateUrl: './paged-content.component.html',
  styleUrls: ['./paged-content.component.less']
})
export class PagedContentComponent {
  @Input() layout!: PagedContent;
}
