import {Component, Input, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Tabs} from "./types/tabs";
import {AccordionModule} from "primeng/accordion";
import {TabComponent} from "./tab/tab.component";
import {MatTabsModule} from "@angular/material/tabs";
import {Tab} from "./tab/types/tab";

@Component({
  selector: 'app-layout-tabs',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, AccordionModule, TabComponent, MatTabsModule],
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.less']
})
export class TabsComponent {
  @Input() layout!: Tabs;

  protected sortedSections(): Tab[] {
    return this.layout.sections.sort((a: Tab, b: Tab) => a.position - b.position);
  }
}
