import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(private http: HttpClient) {
  }

  public sendEmail(
    topic: string,
    firstName: string,
    lastName: string,
    email: string,
    message: string,
  ): Observable<any> {
    return this.http.post<Event>(`${environment.api.url}/ui/v1/contact/send-email`, {
      topic: topic,
      firstName: firstName,
      lastName: lastName,
      email: email,
      message: message,
    })
  }
}
