<div class="contact-form-widget">
  <div class="contact-form--form">
    <form *ngIf="!contactFormSent && !contactFormFailed" (submit)="sendEmail()" [formGroup]="form">
      <div class="form-control">
        <label class="form-control--label">
          <span>Anliegen</span>
          <div>
            <select formControlName="topic">
              <option value="Allgemeine Anfrage">Allgemeine Anfrage</option>
              <option value="Bibliothek/Notenausleihe">Bibliothek/Notenausleihe</option>
              <option value="Veranstaltungen">Veranstaltungen</option>
              <option value="Telemann-Bibliographie">Telemann-Bibliographie</option>
            </select>
            <small class="error-message"
                   *ngIf="form.get('topic')?.invalid && form.get('topic')?.touched && form.get('topic')?.hasError('required')">
              Anliegen ist erforderlich.
            </small>
          </div>
        </label>
      </div>
      <div class="form-control">
        <label class="form-control--label">
          <span>Name</span>
          <div>
            <input type="text" formControlName="lastName">
            <small class="error-message"
                   *ngIf="form.get('lastName')?.invalid && form.get('lastName')?.touched && form.get('lastName')?.hasError('required')">
              Name ist erforderlich.
            </small>
          </div>
        </label>
      </div>
      <div class="form-control">
        <label class="form-control--label">
          <span>Vorname</span>
          <div>
            <input type="text" formControlName="firstName">
            <small class="error-message"
                   *ngIf="form.get('firstName')?.invalid && form.get('firstName')?.touched && form.get('firstName')?.hasError('required')">
              Vorname ist erforderlich.
            </small>
          </div>
        </label>
      </div>
      <div class="form-control">
        <label class="form-control--label">
          <span>Ihre E-Mail-Adresse</span>
          <div>
            <input type="email" formControlName="email">
            <small class="error-message"
                   *ngIf="form.get('email')?.invalid && form.get('email')?.touched && form.get('email')?.hasError('required')">
              E-Mail-Adresse ist erforderlich.
            </small>
            <small class="error-message"
                   *ngIf="form.get('email')?.invalid && form.get('email')?.touched && form.get('email')?.hasError('email')">
              Bitte geben Sie eine gültige E-Mail-Adresse ein.
            </small>
          </div>
        </label>
      </div>
      <div class="form-control">
        <label class="form-control--label">
          <span>Nachricht</span>
          <div>
            <textarea formControlName="message"></textarea>
            <small class="error-message"
                   *ngIf="form.get('message')?.invalid && form.get('message')?.touched && form.get('message')?.hasError('required')">
              Nachricht ist erforderlich.
            </small>
          </div>
        </label>
      </div>
      <div class="contact-form--accept-terms">
        <div></div>
        <div class="form-control">
          <label>
            <span class="checkbox">
              <input type="checkbox" formControlName="data">
              <span></span>
            </span>
          </label>
        </div>
        <span>Ich habe die
          <a href="/datenschutz" target="_blank">Datenschutzbestimmung</a> zur Kenntnis genommen.
        </span>
      </div>
      <div class="form-control">
        <button *ngIf="!sendingInProgress" [disabled]="!form.valid" class="button button-default">
          abschicken
        </button>
        <div class="contact-form--sending" *ngIf="sendingInProgress"></div>
      </div>
    </form>

    <div *ngIf="contactFormSent" class="contact-form--success-message">
      <h3>Glückwunsch!</h3>
      <p>Wir haben Ihre Anfrage erhalten und werden Ihnen so schnell wie möglich antworten</p>
    </div>

    <div *ngIf="contactFormFailed" class="contact-form--success-message">
      <h3>Übermittlung fehlgeschlagen</h3>
      <p>Leider konnte Ihre Nachricht nicht gesendet werden. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es
        erneut. Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte direkt.</p>
    </div>
  </div>
  <div class="contact-form--address">
    <div class="address-icon-row">
      <div class="address-icon-row--icon">
        <img src="assets/images/icons/contact-home.svg" alt="Address" title="Adresse">
      </div>
      <div class="address-icon-row--data">
        <span>{{ widget.data.name }}</span>
        <span>{{ widget.data.line1 }}</span>
        <span>{{ widget.data.line2 }}</span>
        <span>{{ widget.data.street }}</span>
        <span>{{ widget.data.postalCode }} {{ widget.data.town }}</span>
      </div>
    </div>
    <div class="address-icon-row">
      <div class="address-icon-row--icon">
        <img src="assets/images/icons/contact-phone.svg" alt="Phone" title="Phone">
      </div>
      <div class="address-icon-row--data">
        <span>{{ widget.data.phone }}</span>
      </div>
    </div>
  </div>
</div>
