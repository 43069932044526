import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes, UrlSegment} from '@angular/router';
import {menuResolver} from "./menu/menu.resolver";
import {siteOptionsResolver} from "./site-options/site-options.resolver";
import {PageComponent} from "./page/page.component";
import {pageResolver} from "./page/page.resolver";
import {EventComponent} from "./events/event/event.component";
import {eventResolver} from "./events/event.resolver";
import {BlogPostComponent} from "./blog/blog-post/blog-post.component";
import {blogResolver} from "./blog/blog.resolver";
import {AuthGuard} from "./guards/auth.guard";

function slugMatcher(segments: UrlSegment[]) {
  if (segments.length > 0) {
    return {
      consumed: segments,
      posParams: {
        slug: new UrlSegment(segments.join('/'), {})
      }
    };
  }
  return null;
}

const routes: Routes = [
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
      }
    ]
  },
  {
    path: '',
    canActivate: [AuthGuard],
    resolve: {
      menu: menuResolver,
      siteOptions: siteOptionsResolver,
    },
    children: [
      {
        path: '',
        component: PageComponent,
        resolve: {
          page: pageResolver,
        }
      },
      {
        path: 'event/:slug',
        component: EventComponent,
        resolve: {
          event: eventResolver,
        }
      },
      {
        path: 'blog/:slug',
        component: BlogPostComponent,
        resolve: {
          blog: blogResolver,
        }
      },
      {
        matcher: slugMatcher,
        component: PageComponent,
        resolve: {
          page: pageResolver,
        }
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    useHash: false,
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
