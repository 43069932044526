<div class="download-link-widget" [class.loading-file]="!file">
  <div class="download-link--wrapper">
    <div class="download-link--file" [class.no-description]="!widget.data.title && !widget.data.description">
      <div class="file-icon">
      </div>
      <div class="file-name" *ngIf="file">
        <a target="_blank" [href]="getFileUrl()">{{ file.label }}</a>
      </div>
    </div>
    <div class="download-link--content">
      <h6 *ngIf="widget.data.title">{{ widget.data.title }}</h6>
      <p *ngIf="widget.data.description">{{ widget.data.description }}</p>
    </div>
  </div>
</div>
