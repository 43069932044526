import {Component, forwardRef, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {AccordionSection} from "./types/accordion-section";
import {AccordionModule} from "primeng/accordion";
import {LayoutComponent} from "../../layout/layout.component";
import {WidgetComponent} from "../../../widget/widget/widget.component";

@Component({
  selector: 'app-section-accordion-section',
  standalone: true,
  imports: [CommonModule, AccordionModule, forwardRef(() => LayoutComponent), WidgetComponent],
  templateUrl: './accordion-section.component.html',
  styleUrls: ['./accordion-section.component.less']
})
export class AccordionSectionComponent {
  @Input() section!: AccordionSection;

}
