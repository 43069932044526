import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {File, FileUID} from "./file";

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  constructor(private http: HttpClient) {
  }

  public getFile(id: FileUID): Observable<File> {
    return this.http.get<File>(`${environment.api.url}/ui/v1/file/${id}`)
  }
}
