import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SubscribeResponse} from "./types/subscribe-response";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor(private http: HttpClient) {
  }

  public subscribe(
    email: string,
    firstName: string,
    lastName: string,
  ): Observable<SubscribeResponse> {
    return this.http.post<SubscribeResponse>(
      `${environment.api.url}/ui/v1/newsletter/subscribe`,
      {
        email: email,
        firstName: firstName,
        lastName: lastName
      }
    )
  }
}
