import {ResolveFn} from '@angular/router';
import {MenuService} from "./menu.service";
import {inject} from "@angular/core";
import {forkJoin} from "rxjs";

export const menuResolver: ResolveFn<any> = (
  route,
  state,
) => {
  return forkJoin([
    inject(MenuService).get()
  ]);
};
