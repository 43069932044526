<div class="widget-slider">
  <owl-carousel-o [options]="customOptions">

    <ng-container *ngFor="let imageUrl of widget.data.images">
      <ng-template class="slide" carouselSlide>
        <img [alt]="'Das Telemann-Zentrum Magdeburg'" [src]="getImageUrl(imageUrl)"/>
      </ng-template>
    </ng-container>

  </owl-carousel-o>
</div>
