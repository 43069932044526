import {Component, OnInit} from '@angular/core';
import {MenuService} from "../menu.service";
import {Menu} from "../types/menu";
import {SharedModule} from "../../shared/shared.module";
import {RouterLink} from "@angular/router";
import {MenuItemComponent} from "../menu-item/menu-item.component";

@Component({
  imports: [SharedModule, RouterLink, MenuItemComponent],
  selector: 'app-top-menu',
  standalone: true,
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.less']
})


export class TopMenuComponent implements OnInit {
  readonly POSITION: string = 'top';

  public menu: Menu;

  constructor(private menuService: MenuService) {
    this.menu = this.menuService.createEmptyMenu();
  }

  ngOnInit(): void {
    this.menuService.menus$
      .subscribe((menus: Menu[]) => {
        let topMenu = menus.find(m => m.position === this.POSITION);
        if (topMenu !== undefined) {
          this.menu = topMenu;
        }
      });
  }
}
