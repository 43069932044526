import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EstatesAndDonations} from "./types/estates-and-donations";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {EstatesAndDonationsItem} from "./types/estates-and-donations-item";
import {SafePipe} from "../../../pipes/safe/safe.pipe";

@Component({
  selector: 'app-widget-estates-and-donations',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SafePipe],
  templateUrl: './estates-and-donations.component.html',
  styleUrls: [
    './estates-and-donations.component.less',
    '../../../../styles/form/form.less',
  ]
})
export class EstatesAndDonationsComponent implements OnInit {
  @Input() widget!: EstatesAndDonations;
  public searchForm: FormGroup;
  public items!: EstatesAndDonationsItem[];

  public constructor() {
    this.searchForm = new FormGroup({
      query: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.items = this.widget.data.items;
  }

  public onSearchSubmit(): void {
    let query = this.searchForm.getRawValue().query.toLowerCase();

    if (!query) {
      this.items = this.widget.data.items;
      return;
    }

    this.items = this.widget.data.items.filter(
      (item: EstatesAndDonationsItem) => item.firstName.toLowerCase().includes(query) || item.lastName.toLowerCase().includes(query)
    )
  }
}
