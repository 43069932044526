import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Portrait} from "./types/portrait";
import {environment} from "../../../../environments/environment";
import {SafePipe} from "../../../pipes/safe/safe.pipe";

@Component({
  selector: 'app-widget-portrait',
  standalone: true,
  imports: [CommonModule, SafePipe],
  templateUrl: './portrait.component.html',
  styleUrls: ['./portrait.component.less']
})
export class PortraitComponent {
  @Input() widget!: Portrait;
  public modalActive: boolean = false;

  public getImageUrl(): string {
    return `${environment.cdn.url}/cdn/thumbnails/media/${this.widget.data.image}`
  }

  public openModal(): void {
    this.modalActive = true;
  }

  public closeModal(): void {
    this.modalActive = false;
  }
}
