import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PersonPage} from "./types/person-page";
import {environment} from "../../../../environments/environment";
import {SafePipe} from "../../../pipes/safe/safe.pipe";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {faCheckCircle, faEnvelope} from "@fortawesome/free-regular-svg-icons";

@Component({
  selector: 'app-widget-person-page',
  standalone: true,
    imports: [CommonModule, SafePipe, FontAwesomeModule],
  templateUrl: './person-page.component.html',
  styleUrls: ['./person-page.component.less']
})
export class PersonPageComponent {
  @Input() widget!: PersonPage;

  protected readonly faEnvelope = faEnvelope;
  protected readonly faCheckCircle = faCheckCircle;

  protected showEmailCopiedMessage: boolean = false;

  public getImageUrl(): string {
    let thumbnailSize = this.widget.data.cropping ?? 'people';

    if (!thumbnailSize || thumbnailSize === 'full') {
      return `${environment.cdn.url}/cdn/${this.widget.data.image}`;
    }

    return `${environment.cdn.url}/cdn/thumbnails/${thumbnailSize}/${this.widget.data.image}`;
  }

  public getClasses(): string[] {
    let classes = [
      `person-widget--background-${this.widget.data.style}`,
    ];

    if (this.widget.data.image === null) {
      classes.push('person-widget--no-image');
    }

    return classes;
  }

  public copyEmailToClipboard(): void {
    navigator.clipboard.writeText(this.widget.data.email || '').then(() => {
      this.showEmailCopiedMessage = true;
      setTimeout(() => {
        this.showEmailCopiedMessage = false;
      }, 3000);
    });
  }
}
