<header>
  <div class="container container-boxed">
    <div class="header-content">
      <div class="popup-menu--position">
        <app-popup-menu></app-popup-menu>
      </div>
      <div class="logo">
        <a [routerLink]="''">
          <img alt="Telemann" [src]="'assets/layout/logo.svg'"/>
        </a>
      </div>
      <app-top-menu></app-top-menu>
      <div class="social-icons--container">
        <app-social></app-social>
      </div>
    </div>
  </div>
</header>
