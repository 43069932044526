import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaginatorModule} from "primeng/paginator";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NewsletterService} from "../../../newsletter/newsletter.service";

@Component({
  selector: 'app-widget-newsletter',
  standalone: true,
  imports: [CommonModule, PaginatorModule, ReactiveFormsModule],
  templateUrl: './newsletter.component.html',
  styleUrls: [
    './newsletter.component.less',
    '../../../../styles/form/form.less',
  ]
})
export class NewsletterComponent {
  public form: FormGroup;
  public formSubmitted: boolean = false;

  public constructor(
    private newsletterService: NewsletterService,
  ) {
    this.form = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      data: new FormControl(false, [Validators.required, Validators.requiredTrue]),
    });
  }

  subscribe(): void {
    let data = this.form.getRawValue();
    this.newsletterService.subscribe(
      data.email,
      data.firstName,
      data.lastName,
    ).subscribe(response => {
      this.formSubmitted = true;
    });
  }
}
