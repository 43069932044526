<div class="footer-menu">
  <ul class="footer-menu--row-1" *ngIf="firstRow">
    <ng-container *ngFor="let item of firstRow.items">
      <app-menu-item [item]="item" [customClass]="'footer-menu-row-1-item'"></app-menu-item>
    </ng-container>
  </ul>
  <ul class="footer-menu--row-2" *ngIf="secondRow">
    <ng-container *ngFor="let item of secondRow.items">
      <app-menu-item [item]="item" [customClass]="'footer-menu-row-2-item'"></app-menu-item>
    </ng-container>
  </ul>
</div>
