import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivatedRoute, RouterLink} from "@angular/router";
import {map, Observable} from "rxjs";
import {BlogPost} from "../types/blog-post";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-blog-post',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.less']
})
export class BlogPostComponent implements OnInit {
  public blog$!: Observable<BlogPost>;

  public constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
  ) {
  }

  ngOnInit(): void {
    this.blog$ = this.route.data.pipe(
      map(
        data => data['blog']
      )
    );


    this.blog$.subscribe(blog => {
      this.meta.removeTag('name="description"');
      this.titleService.setTitle(blog.title);
      if (blog.metaDescription) {
        this.meta.addTag({name: 'description', content: blog.metaDescription});
      }})
  }

  public getHeaderStyle(blog: BlogPost): object {
    let styles: any = {};

    if (blog.imageUrl) {
      styles.backgroundImage = `url(${blog.imageUrl})`;
    }

    return styles;
  }

}
