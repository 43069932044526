<div class="portrait-widget" (click)="openModal()">
  <div class="portrait--image">
    <img [src]="getImageUrl()" [alt]="widget.data.title" [title]="widget.data.title">
  </div>

  <div class="portrait--content">
    <h3>{{widget.data.title}}</h3>
    <p [innerHtml]="widget.data.description|safe"></p>
  </div>
</div>

<div class="portrait-modal" *ngIf="modalActive">
  <div class="background"></div>
  <div class="portrait-modal-container">
    <div class="portrait-modal-content">
      <div class="portrait-modal--close">
        <button (click)="closeModal()">X</button>
      </div>
      <div class="portrait-modal--title">{{widget.data.popupTitle}}</div>
      <div class="portrait-modal--image">
        <img [src]="getImageUrl()" [alt]="widget.data.title" [title]="widget.data.title">
      </div>
    </div>
  </div>
</div>
