<div class="person-widget">
  <div class="person-widget--main" [ngClass]="getClasses()">
    <div class="person-widget-wrapper">
      <div *ngIf="widget.data.image" class="person-widget--image">
        <img [src]="getImageUrl()" [alt]="widget.data.name" [title]="widget.data.name">
      </div>

      <div class="person-widget--info">
        <h3>{{ widget.data.name }}</h3>
        <h6>{{ widget.data.position }}</h6>

        <p class="person--biography" *ngIf="widget.data.biography" [innerHTML]="widget.data.biography|safe"></p>

        <hr *ngIf="widget.data.email || widget.data.phone || widget.data.linkText">

        <div class="person-widget--info-email" *ngIf="widget.data.email">
          <button (click)="copyEmailToClipboard()">
            <span *ngIf="!showEmailCopiedMessage"><fa-icon [icon]="faEnvelope"></fa-icon> E-Mail kopieren</span>
            <span class="email-copied--success" *ngIf="showEmailCopiedMessage"><fa-icon [icon]="faCheckCircle"></fa-icon> Kopiert!</span>
          </button>
        </div>
        <div class="person-widget--info-phone" *ngIf="widget.data.phone">{{ widget.data.phone }}</div>
        <div class="person-widget--info-link" *ngIf="widget.data.linkUrl">
          <a [routerLink]="widget.data.linkUrl">{{ widget.data.linkText }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
