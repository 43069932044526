import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, tap} from "rxjs";
import {environment} from "../../environments/environment";
import {LoginResponse} from "./types/login-response";
import moment from "moment";
import {JwtHelperService} from "@auth0/angular-jwt";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private http: HttpClient,
        private jwtHelper: JwtHelperService,
    ) {
    }

    public login(email: string, password: string): Observable<LoginResponse> {
        return this.http.post<LoginResponse>(`${environment.api.url}/login_check`, {
            username: email,
            password: password,
        }).pipe(
            tap(res => {
                this.setSession(res)
            })
        );
    }

    private setSession(response: LoginResponse): void {
        let expirationDate = this.jwtHelper.getTokenExpirationDate(response.token);
        const expiresAt = moment(expirationDate);

        localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
        localStorage.setItem("access_token", response.token);
        localStorage.setItem("refresh_token", response.refresh_token);
    }

    public logout(): void {
        localStorage.removeItem("expires_at");
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
    }

    public isLoggedIn(): boolean {
        return moment().isBefore(this.getExpiration());
    }

    private getExpiration() {
        const expiration = localStorage.getItem("expires_at");

        if (expiration === null) {
          return moment();
        }

        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    }

    public getLoggerInUserEmail(): string {
        let token = localStorage.getItem("access_token");
        let data = this.jwtHelper.decodeToken(token || '');
        return data.username;
    }
}
