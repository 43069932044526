import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
  standalone: true,
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  public constructor(
    private sanitizer: DomSanitizer,
  ) {
  }

  transform(value: string|null): unknown {
    if (value === null || value === undefined) {
      return '';
    }
    return this.sanitizer.bypassSecurityTrustHtml(value)
  }

}
