import {ResolveFn} from '@angular/router';
import {forkJoin} from "rxjs";
import {inject} from "@angular/core";
import {SiteOptionsService} from "./site-options.service";

export const siteOptionsResolver: ResolveFn<any> = (
  route,
  state
) => {
  return forkJoin([
    inject(SiteOptionsService).get()
  ]);
};
