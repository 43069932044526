<ng-container *ngIf="blog$ | async as blog">
  <div class="blog-details">
    <header *ngIf="blog.imageUrl" class="blog-header" [ngStyle]="getHeaderStyle(blog)">
    </header>

    <div class="container container-boxed">
      <article class="blog-details--page">
        <div class="blog-details--content-top">
          <div class="title-and-subtitle">
            <h2>{{ blog.title }}</h2>
          </div>
        </div>

        <ng-template [ngIf]="false">
          <div class="blog-details--info">
            <p class="blog-details--date">
              <time>
                <strong>{{ blog.dateCreated | date:'EEEE, dd. MMMM YYYY' }}</strong>
                | {{ blog.dateCreated | date:'HH.mm' }} Uhr
              </time>
            </p>
          </div>
        </ng-template>

        <div class="separator separator-short"></div>

        <div class="blog-details--content" [innerHtml]="blog.text">
        </div>

      </article>

        <div class="button-widget button-widget--align-center">
          <a class="button button-default" [routerLink]="['/de/sidebar/aktuelles']">Aktuelles</a>
        </div>
    </div>
  </div>
</ng-container>
