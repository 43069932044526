import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SocialService} from "./social.service";
import {SocialIcon} from "./types/social-icon";

@Component({
  selector: 'app-social',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.less']
})
export class SocialComponent implements OnInit {

  public socialIcons: SocialIcon[] = [];

  public constructor(
    private socialService: SocialService,
  ) {
  }

  ngOnInit(): void {
    this.socialService.getSocialIcons().subscribe(
      icons => {
        this.socialIcons = icons;
      }
    )
  }

  public getIconUrl(icon: SocialIcon): string {
    return `assets/images/social/${icon.icon}.svg`
  }
}
