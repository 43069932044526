import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BibliographyService} from "../../../bibliography/bibliography.service";
import {Item} from "../../../bibliography/types/item";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SearchForm} from "./types/search-form";
import {Author} from "../../../bibliography/types/author";
import {AbbreviationsService} from "../../../abbreviations/abbreviations.service";
import {Abbreviation} from "../../../abbreviations/types/abbreviation";
import {finalize} from "rxjs";

@Component({
  selector: 'app-widget-bibliography',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './bibliography.component.html',
  styleUrls: [
    './bibliography.component.less',
    '../../../../styles/form/form.less',
  ]
})
export class BibliographyComponent {

  public items!: Item[];
  public searchForm: FormGroup;
  public abbreviations: Abbreviation[] = [];
  protected searching: boolean = false;

  public constructor(
    private bibliographyService: BibliographyService,
    private abbreviationsService: AbbreviationsService,
  ) {
    this.searchForm = this.createSearchForm({
      title: '',
      author: '',
      year: '',
    });

    this.abbreviationsService.list().subscribe(items => {
      this.abbreviations = items;
    })
  }

  private loadEvents(): void {
    this.searching = true;

    let searchValues = this.searchForm.getRawValue() as SearchForm;
    this.bibliographyService.search(
      searchValues.title,
      searchValues.author,
      searchValues.year,
    ).pipe(
      finalize(() => {
        this.searching = false;
      })
    ).subscribe(
      (response: Item[]) => {
        this.items = response;
      }
    );
  }

  private createSearchForm(data: SearchForm): FormGroup {
    return new FormGroup({
        title: new FormControl(data.title),
        author: new FormControl(data.author),
        year: new FormControl(data.year),
      }
    );
  }

  public authorName(author: Author): string {
    if (author.lastName) {
      return `${author.lastName}, ${author.firstName}`
    }

    return author.firstName;
  }

  public onSearchSubmit(): void {
    this.loadEvents()
  }

  public addAbbreviations(text: string): string {
    for (let abbr of this.abbreviations) {
      text = text.replace(new RegExp(`\\b${abbr.code}\\b`, 'd'), `<abbr title="${abbr.description}">${abbr.code}</abbr>`)
    }

    return text;
  }
}
