import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Form} from "./types/form";

@Component({
  selector: 'app-widget-form',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './form.component.html',
  styleUrls: [
    './form.component.less',
    '../../../../styles/form/form.less'
  ]
})
export class FormComponent {
  @Input() widget!: Form;
}
