import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Slider} from "./types/slider";
import {CarouselModule, OwlOptions} from "ngx-owl-carousel-o";
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-widget-slider',
  standalone: true,
  imports: [CommonModule, CarouselModule],
  templateUrl: './slider.component.html',
  styleUrls: [
    '../../../../../node_modules/ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.carousel.min.css',
    '../../../../../node_modules/ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.theme.default.min.css',
    './slider.component.less'
  ]
})
export class SliderComponent {
  @Input() widget!: Slider;

  customOptions: OwlOptions = {
    loop: true,
    touchDrag: true,
    nav: false,
    autoplay: true,
    center: true,
    dots: true,
    autoHeight: true,
    margin: 10,
    autoWidth: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1.3,
      }
    }
  };

  public getImageUrl(imageFileName: string): string {
    return `${environment.cdn.url}/cdn/${imageFileName}`;
  }
}
