import {Component, forwardRef, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WidgetComponent} from "../../../widget/widget/widget.component";
import {Section} from "../../../../page/types/section";
import {LayoutComponent} from "../../layout/layout.component";
import {Widget} from "../../../../page/types/widget";
import {Layout} from "../../../../page/types/layout";

@Component({
  selector: 'app-layout-row-column',
  standalone: true,
  imports: [CommonModule, WidgetComponent, forwardRef(() => LayoutComponent)],
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.less']
})
export class ColumnComponent {
  @Input() section!: Section;

  protected sortedLayouts(): Layout[] {
    return this.section.layouts.sort((a: Layout, b: Layout) => a.position - b.position);
  }

  protected sortedWidgets(): Widget[] {
    return this.section.widgets.sort((a: Widget, b: Widget) => a.position - b.position);
  }
}
