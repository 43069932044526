<ng-container *ngFor="let layout of section.layouts">
  <app-layout [layout]="layout"></app-layout>
</ng-container>

<div class="container container-boxed">
  <div class="accordion-section--content">
    <ng-container *ngFor="let widget of section.widgets">
      <app-widget [widget]="widget"></app-widget>
    </ng-container>
  </div>
</div>
