import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Person} from "./types/person";
import {RouterLink} from "@angular/router";
import {environment} from 'src/environments/environment';
import {SafePipe} from "../../../pipes/safe/safe.pipe";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {faCheckCircle, faEnvelope} from "@fortawesome/free-regular-svg-icons";

@Component({
  selector: 'app-widget-person',
  standalone: true,
  imports: [CommonModule, RouterLink, SafePipe, FontAwesomeModule],
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.less']
})
export class PersonComponent {
  @Input() widget!: Person;

  protected readonly faEnvelope = faEnvelope;
  protected readonly faCheckCircle = faCheckCircle;

  protected showEmailCopiedMessage: boolean = false;

  public getImageUrl(): string {
    let thumbnailSize = this.widget.data.cropping ?? 'people';

    if (!thumbnailSize || thumbnailSize === 'full') {
      return `${environment.cdn.url}/cdn/${this.widget.data.image}`;
    }

    return `${environment.cdn.url}/cdn/thumbnails/${thumbnailSize}/${this.widget.data.image}`;
  }

  public getClasses(): string[] {
    let classes = [
      `person-widget--background-${this.widget.data.style}`,
      `person-widget--layout-${this.widget.data.layout}`
    ];

    if (!this.widget.data.image) {
      classes.push('person-widget--no-image');
    }

    if (this.widget.data.textAlign) {
      classes.push(`text-${this.widget.data.textAlign}`)
    }

    return classes;
  }

  public copyEmailToClipboard(): void {
    navigator.clipboard.writeText(this.widget.data.email || '').then(() => {
      this.showEmailCopiedMessage = true;
      setTimeout(() => {
        this.showEmailCopiedMessage = false;
      }, 3000);
    });
  }
}
