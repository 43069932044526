import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {List} from "./types/list";
import {SafePipe} from "../../../pipes/safe/safe.pipe";

@Component({
  selector: 'app-widget-list',
  standalone: true,
  imports: [CommonModule, SafePipe],
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less']
})
export class ListComponent {
  @Input() widget!: List;
}
