import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Separator} from "./types/separator";

@Component({
  selector: 'app-widget-separator',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './separator.component.html',
  styleUrls: ['./separator.component.less']
})
export class SeparatorComponent {
  @Input() widget!: Separator;

  public getClasses(): string {
    return [
      'separator',
      `align-${this.widget.data.align}`,
      `size-${this.widget.data.size}`,
    ].join(' ');
  }
}
