import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Button} from "./types/button";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-widget-button',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.less']
})
export class ButtonComponent {
  @Input() widget!: Button;

  public getClasses(): string[] {
    return [
      'button',
      `button-${this.widget.data.style}`,
    ];
  }

  public getWidgetClasses(): string[] {
    let classes: string[] = [];

    if (this.widget.data.align) {
      classes.push(`button-widget--align-${this.widget.data.align}`);
    }

    return classes;
  }

  public getUrl(): string {
    return this.widget.data.url ?? '/homepage';
  }
}
