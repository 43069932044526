import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Menu} from "./types/menu";
import {Router, RouterLink} from "@angular/router";
import {MenuDataItem} from "./types/menu-data";

@Component({
  selector: 'app-widget-menu',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit {
  @Input() widget!: Menu;
  public href!: string;

  public constructor(
    private router: Router
  ) {
  }

  ngOnInit() {
    this.href = this.router.url;
  }

  public getItemClasses(item: MenuDataItem): string[] {
    let classes = [];
    if (item.url === this.href) {
      classes.push('current-page')
    }
    return classes;
  }
}
