import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactForm} from "./types/contact-form";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ContactService} from "../../../contact/contact.service";
import {RouterLink} from "@angular/router";
import {MatSelectModule} from "@angular/material/select";

@Component({
  selector: 'app-widget-contact-form',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterLink, MatSelectModule],
  templateUrl: './contact-form.component.html',
  styleUrls: [
    './contact-form.component.less',
    '../../../../styles/form/form.less',
  ]
})
export class ContactFormComponent {
  @Input() widget!: ContactForm;
  public form: FormGroup;
  public contactFormSent: boolean = false;
  public contactFormFailed: boolean = false;
  public sendingInProgress: boolean = false;

  public constructor(
    private contactService: ContactService,
  ) {
    this.form = new FormGroup({
      topic: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', [Validators.required]),
      data: new FormControl(false, [Validators.required, Validators.requiredTrue]),
    });
  }

  public sendEmail(): void {
    this.sendingInProgress = true;
    let formData = this.form.getRawValue();
    this.contactService.sendEmail(
      formData.topic,
      formData.firstName,
      formData.lastName,
      formData.email,
      formData.message,
    ).subscribe(response => {
        this.sendingInProgress = false;
        this.contactFormSent = true;
        this.form.reset();
      },
      error => {
        this.contactFormFailed = true;
      })
  }
}
