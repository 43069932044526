import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Timeline} from "./types/timeline";
import {SafePipe} from "../../../pipes/safe/safe.pipe";

@Component({
  selector: 'app-widget-timeline',
  standalone: true,
  imports: [CommonModule, SafePipe],
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.less']
})
export class TimelineComponent {
  @Input() widget!: Timeline;

  public getClasses(): string[] {
    let classes = [];
    if (this.widget.data.hideBullets) {
      classes.push('no-bullets');
    }

    if (this.widget.data.style) {
      classes.push(`timeline-style-${this.widget.data.style}`)
    }

    return classes;
  }
}
