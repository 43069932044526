import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Blog} from "./types/blog";
import {RouterLink} from "@angular/router";
import {BlogPost} from 'src/app/blog/types/blog-post';
import {BlogService} from 'src/app/blog/blog.service';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-widget-blog',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.less']
})
export class BlogComponent implements OnInit {
  @Input() widget!: Blog;
  public blogPosts: BlogPost[] = [];

  public constructor(
    private blogService: BlogService,
  ) {
  }

  ngOnInit(): void {
    this.blogService.getBlogPosts(
      this.widget.data.filters?.categories,
      this.widget.data.limit,
    ).subscribe(
      posts => this.blogPosts = posts
    )
  }

  public excerpt(article: BlogPost): string {
    let htmlStripped = article.text ? article.text.replace(/<[^>]+>/gm, '') : '';
    return htmlStripped.length > 450 ? htmlStripped.slice(0, 450) + '...' : htmlStripped;
  }

  public getStyles(article: BlogPost): object {
    return {
      backgroundImage: `url(${environment.cdn.url}/blogs/thumbnails/medium/${article.image})`
    }
  }
}
