import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {EventCategoryListItem} from "./types/event-category-list-item";
import {Event} from "./types/event";
import {EventsFilterResponse} from "./types/events-filter-response";

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(private http: HttpClient) {
  }

  public getCategoriesList(): Observable<EventCategoryListItem[]> {
    return this.http.get<EventCategoryListItem[]>(`${environment.api.url}/ui/v1/event-categories`);
  }

  public getEvents(
    categoryIds?: number[],
    dateFrom?: string,
    dateTo?: string,
    limit?: number,
  ): Observable<EventsFilterResponse> {
    let params: HttpParams = new HttpParams();
    if (categoryIds) {
      categoryIds.forEach(id => params = params.append('categoryIds[]', id))
    }
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom);
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo);
    }
    if (limit) {
      params = params.append('limit', limit);
    }

    return this.http.get<EventsFilterResponse>(`${environment.api.url}/ui/v1/events`, {
      params: params
    });
  }

  public getEventBySlug(slug: string): Observable<Event> {
    return this.http.get<Event>(`${environment.api.url}/ui/v1/event/by-slug/${slug}`);
  }
}
