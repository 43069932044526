<div class="accordion">
  <p-accordion (activeIndexChange)="activeIndexChange($event)" [activeIndex]="activeIndex" [multiple]="layout.data.allowMultiple">
    <p-accordionTab
      *ngFor="let section of sortedSections()">
      <ng-template pTemplate="header">
        <div class="accordion-header">
          <div class="accordion-header--container container container-boxed">
            <h3>{{section.data.title}}</h3>
          </div>
        </div>
      </ng-template>
      <div class="container container-boxed">
        <app-section-accordion-section [section]="section"></app-section-accordion-section>
      </div>
    </p-accordionTab>
  </p-accordion>
</div>
