import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {PlaylistSong} from "../types/playlist-song";
import {NgIf, NgStyle} from "@angular/common";
import {AudioService} from "../../../../audio/audio.service";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-html5audio',
  standalone: true,
  imports: [
    NgIf,
    NgStyle
  ],
  templateUrl: './html5audio.component.html',
  styleUrls: ['./html5audio.component.less']
})
export class Html5audioComponent implements OnInit {
  @Input() song!: PlaylistSong;
  @ViewChild('player') player!: ElementRef<HTMLAudioElement>;
  public percentagePlayed: number = 0;
  public duration: number = 0;

  ngOnInit() {
    this.audioService.getAudioStartedEvent().subscribe(event => {
      this.pauseSong();
      this.player.nativeElement.fastSeek(0);
    });
  }

  public constructor(private audioService: AudioService) {
  }

  public onPlay(): void {
    this.song.isPlaying = true;
  }

  public onPause(): void {
    this.song.isPlaying = false;
  }

  public onTimeUpdate(): void {
    this.percentagePlayed = this.duration / this.player.nativeElement.currentTime;
  }

  public onLoadedMetadata(): void {
    this.duration = this.player.nativeElement.duration;
  }

  public getDuration(): string {
    let minutes: number = Math.floor(this.duration / 60);
    let seconds: number = Math.round(this.duration - minutes * 60);

    let secondsPadded: string = seconds.toString();
    if (seconds < 10) {
      secondsPadded = `0${secondsPadded}`;
    }
    return `${minutes}:${secondsPadded}`;
  }

  public playSong(): void {
    this.audioService.sendAudioStartedEvent();
    this.player.nativeElement.play();
  }

  public pauseSong(): void {
    this.player.nativeElement.pause();
  }

  public getAudioUrl(song: PlaylistSong): string {
    return `${environment.cdn.url}/cdn/${song.song.url}`;
  }

  public getPlayedPercentage(): number {
    return this.player.nativeElement.currentTime / this.duration * 100
  }

  public getPlayedStyle(): object {
    if (this.player == undefined) {
      return {};
    }

    return {
      width: `${this.getPlayedPercentage()}%`
    }
  }
}
