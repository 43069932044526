import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Accordion} from "./types/accordion";
import {AccordionModule} from "primeng/accordion";
import {AccordionSectionComponent} from "./accordion-section/accordion-section.component";
import {AccordionSection} from "./accordion-section/types/accordion-section";

@Component({
  selector: 'app-layout-accordion',
  standalone: true,
  imports: [CommonModule, AccordionModule, AccordionSectionComponent],
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.less']
})
export class AccordionComponent implements OnInit {
  @Input() layout!: Accordion;
  protected activeIndex: number | number[] | null | undefined;

  ngOnInit(): void {
    this.activeIndex = this.getActiveIndex();
  }

  protected activeIndexChange(index : number | number[]){
    this.activeIndex = index
  }

  private getActiveIndex(): number[] | number | undefined {
    if (this.layout.data.allowMultiple) {
      let expanded: number[] = [];
      this.layout.sections.forEach((s, i) => {
        if (s.data.expanded) {
          expanded.push(i);
        }
      })

      return expanded;
    }

    return this.layout.sections.findIndex(s => s.data.expanded);
  }

  protected sortedSections(): AccordionSection[] {
    return this.layout.sections.sort((a: AccordionSection, b: AccordionSection) => a.position - b.position);
  }
}
