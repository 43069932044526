<div class="person-page-widget">
  <div [ngClass]="getClasses()">
    <div class="person-widget-wrapper">
      <div>
        <div *ngIf="widget.data.image" class="person-widget--image">
          <img [src]="getImageUrl()" [alt]="widget.data.position" [title]="widget.data.position">
        </div>

        <div class="person-widget--info">
          <h6>{{widget.data.position}}</h6>
          <hr *ngIf="widget.data.image === null">
          <div class="person-widget--info-email" *ngIf="widget.data.email">
            <button (click)="copyEmailToClipboard()">
              <span *ngIf="!showEmailCopiedMessage"><fa-icon [icon]="faEnvelope"></fa-icon> E-Mail kopieren</span>
              <span class="email-copied--success" *ngIf="showEmailCopiedMessage"><fa-icon [icon]="faCheckCircle"></fa-icon> Kopiert!</span>
            </button>
          </div>
          <div class="person-widget--info-phone" *ngIf="widget.data.phone">{{widget.data.phone}}</div>
        </div>
      </div>
      <div>
        <div class="person-widget--biography" [innerHtml]="widget.data.biography|safe"></div>
      </div>
    </div>
  </div>
</div>
