import {Component, Input, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Text} from "./types/text";
import {DomSanitizer} from "@angular/platform-browser";
import {AppModule} from "../../../app.module";
import {SafePipe} from "../../../pipes/safe/safe.pipe";

@Component({
  selector: 'app-widget-text',
  standalone: true,
  imports: [CommonModule, SafePipe],
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class TextComponent {
  @Input() widget!: Text;

  public getClasses(): string[] {
    return [
      'text-widget',
      `text-${this.widget.data.textAlign}`
    ];
  }
}
