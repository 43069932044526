<article class="promo-block" [ngClass]="getClasses()">
  <figure *ngIf="widget.data.image">
    <div class="promo-block--image" [style]="getImageStyles()"></div>
  </figure>

  <h4>{{widget.data.title}}</h4>
  <p class="text-small" [innerHtml]="widget.data.text|safe"></p>

  <div class="promo-block-button">
    <a class="button" [routerLink]="widget.data.buttonLink">
      {{widget.data.buttonText}}
    </a>
  </div>
</article>
