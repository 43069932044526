<div class="bibliography-filters">
  <form (submit)="onSearchSubmit()" [formGroup]="searchForm" class="form form-horizontal">
    <div class="form-control">
      <input type="text" [formControlName]="'author'" placeholder="Autor">
    </div>
    <div class="form-control">
      <input type="text" [formControlName]="'title'" placeholder="Titel">
    </div>
    <div class="form-control">
      <input type="text" [formControlName]="'year'" placeholder="Jahr">
    </div>
    <div class="form-control">
      <button class="button button-default">Suche</button>
    </div>
  </form>
</div>

<div *ngIf="searching" class="bibliography--searching">
  <img src="../../../../assets/images/icons/loading.svg"/>
</div>

<div *ngIf="!searching" class="bibliography-items">
  <ul class="list-bulleted" *ngIf="items">
    <li *ngFor="let item of items">
      <div>
        <ng-container *ngIf="item.authors.length">
          <span class="item-author" *ngFor="let author of item.authors">{{ authorName(author) }}</span>:
        </ng-container>
        <h4 [innerHTML]="addAbbreviations(item.title)"></h4>
      </div>
      <div class="bibliography-item--url" *ngIf="item.url">
        <a target="_blank" [href]="item.url">{{ item.url }}</a>
      </div>
      <div class="bibliography-item--recension" *ngIf="item.extra">
        <p><i>Rezensionen:</i></p>
        <p>{{ item.extra }}</p>
      </div>
    </li>
  </ul>
</div>
