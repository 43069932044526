import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {Abbreviation} from "./types/abbreviation";

@Injectable({
  providedIn: 'root'
})
export class AbbreviationsService {

  constructor(private http: HttpClient) {
  }

  public list(): Observable<Abbreviation[]> {
    return this.http.get<Abbreviation[]>(`${environment.api.url}/ui/v1/abbreviations`);
  }
}
