import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {Item} from "./types/item";

@Injectable({
  providedIn: 'root'
})
export class BibliographyService {

  constructor(private http: HttpClient) {
  }

  public search(
    title?: string,
    author?: string,
    year?: string,
  ): Observable<Item[]> {
    let params: HttpParams = new HttpParams();
    if (title) {
      params = params.append('title', title)
    }
    if (author) {
      params = params.append('author', author);
    }
    if (year) {
      params = params.append('year', year);
    }

    return this.http.get<Item[]>(`${environment.api.url}/ui/v1/bibliography`, {
      params: params
    });
  }
}
