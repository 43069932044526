<div class="link-block-widget" [ngClass]="getClasses()">
  <div class="link-block-widget--inner-content">
    <a class="no-external-link-icon" *ngIf="isExternalUrl()"  [href]="getUrl()" target="_blank">
      <ng-container *ngIf="false; else content"></ng-container>
    </a>
    <a class="no-external-link-icon" *ngIf="!isExternalUrl() && widget.data.url || (!widget.data.url && !widget.data.fileUrl)"
       [routerLink]="getUrl()">
      <ng-container *ngIf="false; else content"></ng-container>
    </a>
    <a class="no-external-link-icon" *ngIf="!isExternalUrl() && !widget.data.url && widget.data.fileUrl" [href]="getFileUrl()" target="_blank">
      <ng-container *ngIf="false; else content"></ng-container>
    </a>
  </div>
</div>

<ng-template #content>
  <article>
    <div *ngIf="widget.data.image" class="link-block-image">
      <img [title]="getAltText()" [src]="getImageUrl()" [alt]="getAltText()">
    </div>

    <div class="link-block-content" [class.is-external-link]="isExternalUrl()">
      <h4 [innerHtml]="widget.data.title">
        <a *ngIf="widget.data.fileUrl">
          <img src="assets/images/icons/icon-download.svg" title="Download" alt="Download">
        </a>
      </h4>
      <p *ngIf="widget.data.description">
        {{ widget.data.description }}
      </p>
    </div>
  </article>
</ng-template>
