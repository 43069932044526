import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Playlist} from "./types/playlist";
import {PlaylistSong} from "./types/playlist-song";
import {Song} from "./types/song";
import {Html5audioComponent} from "./html5audio/html5audio.component";

@Component({
  selector: 'app-widget-playlist',
  standalone: true,
  imports: [CommonModule, Html5audioComponent],
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.less']
})
export class PlaylistComponent implements OnInit {
  @Input() widget!: Playlist;
  public songs: PlaylistSong[] = [];

  ngOnInit(): void {
    this.songs = this.widget.data.songs.map((s: Song) => {
      return {
        song: s,
        isPlaying: false
      } as PlaylistSong;
    })
  }
}
