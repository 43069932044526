import {Injectable} from '@angular/core';
import {Menu} from "./types/menu";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable, ReplaySubject, tap} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private menus: ReplaySubject<Menu[]> = new ReplaySubject<Menu[]>(1);

  constructor(private http: HttpClient) {
  }

  get menus$(): Observable<Menu[]> {
    return this.menus.asObservable();
  }

  public get(): Observable<Menu[]> {
    return this.http.get<Menu[]>(`${environment.api.url}/ui/v1/menus`)
      .pipe(
        tap((menus: Menu[]) => {
          this.menus.next(menus)
        })
      );
  }

  public createEmptyMenu(): Menu {
    return {
      id: 0,
      position: '',
      name: '',
      items: []
    }
  }
}
