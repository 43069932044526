import {Component, OnInit} from '@angular/core';
import {Menu} from "../types/menu";
import {MenuService} from "../menu.service";
import {SharedModule} from "../../shared/shared.module";
import {MenuItemComponent} from "../menu-item/menu-item.component";

@Component({
  selector: 'app-footer-menu',
  standalone: true,
    imports: [SharedModule, MenuItemComponent],
  templateUrl: './footer-menu.component.html',
  styleUrls: ['./footer-menu.component.less']
})
export class FooterMenuComponent implements OnInit {
  readonly POSITION_FIRST: string = 'footer-row-1';
  readonly POSITION_SECOND: string = 'footer-row-2';

  public firstRow: Menu;
  public secondRow: Menu;

  constructor(private menuService: MenuService) {
    this.firstRow = this.menuService.createEmptyMenu();
    this.secondRow = this.menuService.createEmptyMenu();
  }

  ngOnInit(): void {
    this.menuService.menus$
      .subscribe((menus: Menu[]) => {
        let first = menus.find(m => m.position === this.POSITION_FIRST);
        if (first !== undefined) {
          this.firstRow = first;
        }

        let second = menus.find(m => m.position === this.POSITION_SECOND);
        if (second !== undefined) {
          this.secondRow = second;
        }
      });
  }
}
